import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import "./news.scss"
import { NewsCard } from "../components/NewsCard"

const CATEGORY_CUTOFF_LENGTH = 6
export default function News({
  data: {
    strapiNewsPage: {
      seo,
      title,
      description,
      readMoreText,
      categoryExpandText,
    },
    allStrapiNewsCategory: { nodes: newsCategory },
    allStrapiNewsItem,
    allStrapiExternalNewsItem,
  },
}) {
  const newsList = newsCategory.map(c => ({ ...c }))

  allStrapiNewsItem.group.forEach(group => {
    const newsCategoryIndex = newsList.findIndex(c => c.id === group.fieldValue)
    newsList[newsCategoryIndex].news = group.nodes
  })

  allStrapiExternalNewsItem.group.forEach(group => {
    const newsCategoryIndex = newsList.findIndex(c => c.id === group.fieldValue)
    const news = group.nodes.map(n => ({
      id: n.id,
      title: n.title,
      description: n.description,
      author: {
        name: n.authorName,
      },
      publishedAt: n.publishDate,
      images: [n.image],
      linkTo: n.linkTo,
      isExternalNews: true,
    }))
    newsList[newsCategoryIndex].news = [
      ...(newsList[newsCategoryIndex]?.news || []),
      ...news,
    ]
  })
  newsList.forEach(g =>
    g.news.sort(
      (a, b) =>
        new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    )
  )

  return (
    <Layout showNewsletterCTA>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname="news"
      />
      <section className="section is-medium" id="news-title">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <h1 className="title is-1">{title}</h1>
              <div className="my-3">{description}</div>
            </div>
          </div>
        </div>
      </section>
      {newsList?.map(c => (
        <section className="section" key={c.id}>
          <div className="container is-flex is-flex-direction-column">
            <Link
              to={"/" + c.slug + "/"}
              className="is-flex is-align-items-center py-6 news-category-title"
            >
              <div className="section-title-decoration mr-3"></div>
              <div className="section-title ">{c.title}</div>
            </Link>
            <div className="columns is-multiline">
              {c?.news?.map((n, i) =>
                i > CATEGORY_CUTOFF_LENGTH - 1 ? null : (
                  <div
                    className="column is-6 is-4-widescreen is-flex"
                    key={n.id}
                  >
                    <NewsCard
                      categorySlug={c.slug}
                      news={n}
                      readMoreText={readMoreText}
                      isExternalNews={n.isExternalNews}
                    />
                  </div>
                )
              )}
            </div>
            {c.news.length > CATEGORY_CUTOFF_LENGTH && (
              <Link to={"/" + c.slug + "/"} className="is-align-self-flex-end mt-4">
                {categoryExpandText}
              </Link>
            )}
          </div>
        </section>
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    strapiNewsPage {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      title
      description
      readMoreText
      categoryExpandText
    }
    allStrapiNewsCategory(sort: { fields: strapi_id }) {
      nodes {
        title
        slug
        id
      }
    }
    allStrapiNewsItem {
      group(field: news_category___id) {
        nodes {
          id
          publishedAt
          slug
          title
          description
          author {
            name
          }
          images {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        fieldValue
      }
    }
    allStrapiExternalNewsItem {
      group(field: news_category___id) {
        nodes {
          id
          title
          description
          authorName
          publishDate
          linkTo
          news_category {
            id
            title
          }
          image {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        fieldValue
      }
    }
  }
`
