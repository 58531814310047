import React from 'react'
import SiteImage from "./SiteImage"
import { Link } from "gatsby"
import './NewsCard.scss'

export function NewsCard({ categorySlug, news, readMoreText, isExternalNews }) {
  return (
    <div className="news-card is-flex is-flex-direction-column">
      <SiteImage className={`news-card-image`} image={news.images[0]} alt="" />
      <div className="m-4 is-flex-grow-1  is-flex is-flex-direction-column">
        <div className="title is-6">{news.title}</div>
        <div>
          <span className="has-text-primary">{news.author.name}</span>{" "}
          <span className="ml-3">
            {new Date(news.publishedAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </div>
        <div className="my-3 is-flex is-flex-direction-column is-align-items-start is-flex-grow-1">
          <div className="news-description is-flex-grow-1">
            {news.description}
          </div>
          <div className="is-align-self-flex-end">
            {isExternalNews ? (
              <a href={news.linkTo} target="_blank" rel="noreferrer">
                {readMoreText}
              </a>
            ) : (
              <Link to={`/${categorySlug}/${news.slug}/`}>{readMoreText}</Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
